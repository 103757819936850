import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import DatePickerRangeComponent from "../../components/DatePickerRange";
import styled from "./style.module.css";
import { useQuery } from "react-query";
import { getRevenueByTherapist } from "../../api/dailyReport";
import usePaginate from "../../hooks/usePaginate";
import TablePaginationLayout from "../../layouts/TablePagination";
import { getCountOrderNominatation } from "../../utils";
import { STALE_TIME_DEFAULT } from "../../constants";

const styleTableHead = {
  backgroundColor: "#000",
  color: "#fff",
  width: "100px",
  display: "block",
  textAlign: "center",
  verticalAlign: "middle",
  padding: "auto",
  paddingLeft: 0,
  paddingRight: 0,
};

const styleTableBody = {
  ...styleTableHead,
  color: "#000",
  backgroundColor: "transparent",
};

const RevenueTherapist = ({ dataNominatation }) => {
  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const { page, perPage, total, changePage, setTotal, changePerPage } =
    usePaginate(0, 10);

  const [data, setData] = useState([]);

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const { data: dataTherapist } = useQuery(
    ["list_revenue_therapist", startDate, endDate, page],
    () => {
      return getRevenueByTherapist({
        start_date: startDate.format("YYYY-MM-DD"),
        end_date: endDate.format("YYYY-MM-DD"),
        per_page: 10,
        page: page + 1,
      });
    },
    {
      keepPreviousData: true,
      staleTime: STALE_TIME_DEFAULT,
    }
  );

  useEffect(() => {
    if (dataTherapist) {
      setData(dataTherapist.data.data);
      setTotal(dataTherapist.data.total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTherapist]);

  return (
    <Container maxWidth="xl">
      <div className={`${styled.dateRangeWrapper}`}>
        <Typography
          sx={{
            fontSize: {
              md: 20,
              xs: 10,
            },
            letterSpacing: {
              md: "3px",
              xs: 0,
            },
            color: "#fff",
            textDecoration: "none",
          }}
        >
          セラピスト別詳細
        </Typography>
        <div className={`${styled.dateRangeContainer}`}>
          <DatePickerRangeComponent
            onDateChange={(start, end) => handleDateChange(start, end)}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </div>

      <Container
        // maxWidth="xl"
        sx={{
          padding: "0px !important",
        }}
      >
        <Box sx={{ marginTop: "20px" }}>
          <Paper
            sx={{
              backgroundColor: "rgb(255, 255, 255)",
              color: "rgba(0, 0, 0, 0.87)",
              transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              borderRadius: "4px",
              boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
              width: "100%",
              marginBottom: "16px",
              overflow: "auto",
            }}
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow sx={{ display: "flex" }}>
                    <TableCell sx={{ ...styleTableHead, width: "200px" }}>
                      セラピスト
                    </TableCell>
                    <TableCell sx={styleTableHead}>出勤数</TableCell>
                    <TableCell sx={styleTableHead}>総接客数</TableCell>
                    {dataNominatation.map((item) => (
                      <TableCell key={item.id} sx={styleTableHead}>
                        {item.name}
                      </TableCell>
                    ))}
                    <TableCell sx={styleTableHead}>本指名率</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item, index) => (
                    <TableRow
                      key={item.therapist_id}
                      sx={{
                        display: "flex",
                        backgroundColor:
                          index % 2 === 0 ? "rgba(0, 0, 0, 0.04)" : "#fff",
                      }}
                    >
                      <TableCell sx={{ ...styleTableBody, width: "200px" }}>
                        {item.therapist_name}
                      </TableCell>
                      <TableCell sx={styleTableBody}>
                        {item.count_working_days || 0}日
                      </TableCell>
                      <TableCell sx={styleTableBody}>
                        {item.total_customer || 0}人
                      </TableCell>
                      {dataNominatation.map((itemNomination) => (
                        <TableCell key={item.id} sx={styleTableBody}>
                          {getCountOrderNominatation(
                            item.nominations || [],
                            itemNomination.id
                          )}
                        </TableCell>
                      ))}
                      <TableCell sx={styleTableBody}>0.00%</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationLayout
              rowsPerPage={perPage}
              total={total}
              page={page}
              rowsPerPageOptions={[10]}
              onPageChange={changePage}
              handleChangeRowsPerPage={changePerPage}
            />
          </Paper>
        </Box>
      </Container>
    </Container>
  );
};

export default RevenueTherapist;
