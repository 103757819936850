import {
  Box,
  FormControl,
  InputAdornment,
  InputBase,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { getListCustomer } from "../../api/customer";
import usePaginate from "../../hooks/usePaginate";
import TablePaginationLayout from "../../layouts/TablePagination";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  zIndex: 2,
  maxWidth: "calc(100% - 40px)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  maxHeight: "100vh",
  overflowY: "auto",
  padding: "24px",
};
const colums = [
  { label: "ID", value: "id", width: 5 },
  {
    label: "名前",
    value: "name",
    width: 40,
    alignContent: "left",
  },
  {
    label: "電話番号",
    value: "tel",
    width: 20,
  },
  {
    label: "メモ",
    value: "remarks",
    width: 35,
  },
];

const ModalSearchCustomer = ({ open, setOpen, setValue, typeModal }) => {
  const [keySearch, setKeySearch] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [clickCount, setClickCount] = useState(0);

  const { page, perPage, total, setPage, changePage, setTotal, changePerPage } =
    usePaginate();

  const getDateCustomer = async (page) => {
    try {
      const params = {
        [typeModal]: keySearch.trim(),
        page: page + 1,
      };
      const response = await getListCustomer(params);
      setCustomerList(response?.data?.data || []);
      setTotal(response?.data.total);
    } catch (error) {
      setCustomerList([]);
      setTotal(0);
    }
  };

  useEffect(() => {
    getDateCustomer(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeModal, page]);

  const handleSearch = async () => {
    if (page === 0) await getDateCustomer(page);
    else setPage(0);
  };

  const handleClickCustomer = (customer) => {
    setClickCount((prev) => prev + 1);
    if (clickCount + 1 >= 2) {
      setValue("customer_id", customer.id || "");
      setValue("customer_name", customer.name || "");
      setValue("customer_tel", customer.tel || "");
      setKeySearch("");
      setOpen(false);
      setClickCount(0);
    }

    setTimeout(() => {
      setClickCount(0);
    }, 300);
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setKeySearch("");
      }}
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <FormControl
            className="MuiFormControl-marginNormal"
            sx={{ margin: "16px 0 8px" }}
          >
            <InputBase
              className="MuiInput-root MuiInput-underline"
              id="search_key"
              name="search_key"
              placeholder="検索"
              type="text"
              value={keySearch}
              onChange={(e) => setKeySearch(e.target.value)}
              inputProps={{ className: "MuiInputBase-input css-mnn31" }}
              aria-invalid="false"
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon
                    sx={{ cursor: "pointer" }}
                    onClick={handleSearch}
                  />
                </InputAdornment>
              }
              sx={{
                marginTop: "16px",
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before":
                  {
                    borderBottom: "1px solid rgb(0, 0, 0, 0.42)",
                    left: 0,
                    bottom: 0,
                    content: '""',
                    position: "absolute",
                    right: 0,
                    transition:
                      "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    pointerEvents: "none",
                  },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover::before":
                  {
                    borderBottom: "2px solid rgb(0, 0, 0)",
                  },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::after":
                  {
                    borderBottom: "2px solid rgb(25, 118, 210)",
                    left: 0,
                    bottom: 0,
                    content: '""',
                    position: "absolute",
                    right: 0,
                    transform: "scale(0)",
                    transition:
                      "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                    pointerEvents: "none",
                  },
              }}
            />
          </FormControl>
        </Box>

        <Paper
          sx={{
            marginTop: "16px",
            backgroundColor: "#fff",
            boxShadow: "none",
          }}
        >
          <TableContainer>
            <Table aria-labelledby="tableTitle">
              <TableHead>
                <TableRow>
                  {colums.map((item) => (
                    <TableCell
                      key={item.value}
                      width={item.width}
                      align={item.alignContent ?? "center"}
                      sx={{ padding: "8px 16px", color: "#000" }}
                    >
                      {item.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {customerList.map((customer) => (
                  <TableRow
                    key={customer.id}
                    className="MuiTableRow-hover"
                    onClick={() => handleClickCustomer(customer)}
                  >
                    {colums.map((item) => (
                      <TableCell
                        key={item.value}
                        align={item.alignContent ?? "center"}
                        sx={{ color: "#000" }}
                      >
                        {customer[item.value]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePaginationLayout
            rowsPerPage={perPage}
            total={total}
            page={page}
            rowsPerPageOptions={[50]}
            onPageChange={changePage}
            handleChangeRowsPerPage={() => {
              setPage(0);
              changePerPage();
            }}
          />
        </Paper>
      </Box>
    </Modal>
  );
};

export default ModalSearchCustomer;
