import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import HeaderReservationManagement from "../../layouts/HeaderReservationManagement";
import Navbar from "../../layouts/Navbar";
import TitleComponent from "../../components/Title";
import NavigationSaleMGR from "../../components/NavSaleMGR";
import useRevenueTop from "../../features/revenue";

function SalesManagement() {
  const { arrRevenues, getTodayRevenue } = useRevenueTop();
  const revenuesRecentDay = arrRevenues.length ? getTodayRevenue() : {
    total_price: 0,
    total_customer: 0,
  };

  return (
    <>
      <HeaderReservationManagement />
      <Navbar />
      <NavigationSaleMGR />
      <Container maxWidth="md" sx={{ marginTop: "45px" }}>
        <TitleComponent valueText={"売上集計"} />
      </Container>
      <Container maxWidth="md">
        <Box>
          <Typography
            component="h2"
            sx={{
              margin: "0px 0px 40px",
              fontSize: "20px",
              color: "rgb(255, 255, 255);",
              letterSpacing: "3px",
              textAlign: "left",
            }}
          >
            【本日の売上】
          </Typography>
          <Paper className="MuiTableContainer-root">
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      padding: "16px",
                      backgroundColor: "rgb(0, 0, 0)",
                      color: "rgb(255, 255, 255)",
                    }}
                  >
                    <TableSortLabel>総売上</TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      padding: "16px",
                      backgroundColor: "rgb(0, 0, 0)",
                      color: "rgb(255, 255, 255)",
                    }}
                  >
                    <TableSortLabel>総客数</TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    "&:last-child td": {
                      border: 0,
                    },
                    "&:nth-of-type(2n+1)": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <TableCell
                    align="center"
                    sx={{
                      padding: "16px",
                      fontSize: "14px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 36,
                      }}
                    >
                      {revenuesRecentDay.total_price}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      padding: "16px",
                      fontSize: "14px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 36,
                      }}
                    >
                      {revenuesRecentDay.total_customer}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Box>
        <Box sx={{ marginTop: 5 }}>
          <Typography
            component="h2"
            sx={{
              margin: "0px 0px 40px",
              fontSize: "20px",
              color: "rgb(255, 255, 255);",
              letterSpacing: "3px",
              textAlign: "left",
            }}
          >
            【本日の売上】
          </Typography>
          <Paper className="MuiTableContainer-root">
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  {arrRevenues.map((arrRevenues, index) => (
                    <TableCell
                      key={index}
                      align="center"
                      sx={{
                        backgroundColor: "rgb(0, 0, 0)",
                        color: "rgb(255, 255, 255)",
                      }}
                    >
                      {arrRevenues.date}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {arrRevenues.map((revenue, index) => (
                    <TableCell key={index} align="center">
                      <Typography variant="body1">
                        {revenue.total_price}
                      </Typography>
                      <Typography variant="body1">
                        {revenue.total_customer}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Box>
      </Container>
    </>
  );
}

export default SalesManagement;
