import { useEffect, useState } from "react";
import { getListRevenue } from "../../api/revenue";
import { useQuery } from "react-query";

const useRevenueTop = () => {
  const [arrRevenues, setArrRevenues] = useState([]);
  const typeSearch = "by-date"

  const fetchRevenueData = async () => {
    const today = new Date();
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - 3);
    const enDate = new Date(today);
    enDate.setDate(today.getDate() + 3);
    const start_date = startDate.toISOString().split("T")[0];
    const end_date = enDate.toISOString().split("T")[0];

    const params = {
      start_date,
      end_date,
    };
    const data = await getListRevenue(typeSearch, params);
    return data;
  };

  const { data } = useQuery(
    ["revenue"],
    () => fetchRevenueData(),
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (data) {
      setArrRevenues(transformRevenueData(data.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const transformRevenueData = (data) => {
    return data.map((revenue) => (
      {
        date: formatJapaneseDate(revenue.date),
        total_price: formatCurrency(revenue.total_price),
        total_customer: formatNumberWithUnit(revenue.total_customer, "人"),
      }));
  };

  const formatJapaneseDate = (date) => {
    const d = new Date(date);
    return `${d.getMonth() + 1}月${d.getDate()}日`;
  };

  const formatCurrency = (value) => {
    return `${parseInt(value).toLocaleString()}円`;
  };

  const formatNumberWithUnit = (value, unit) => {
    return `${parseInt(value)}${unit}`;
  };

  const getTodayRevenue = () => {
    const today = new Date();
    const formattedToday = formatJapaneseDate(today.toISOString().split("T")[0]);
    const todayRevenue = arrRevenues.find(
      (revenue) => revenue.date === formattedToday
    );

    return todayRevenue;
  };

  return {
    arrRevenues,
    getTodayRevenue
  };
};

export default useRevenueTop;
