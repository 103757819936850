import {
  Box,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import HeaderReservationManagement from "../../layouts/HeaderReservationManagement";
import Navbar from "../../layouts/Navbar";
import TitleComponent from "../../components/Title";
import NavigationSaleMGR from "../../components/NavSaleMGR";
import useRevenueByTherapist from "../../features/revenue/byTherapist";
import DatePickerRangeComponent from "../../components/DatePickerRange";
import TablePaginationLayout from "../../layouts/TablePagination";

const headers = ["", "総売上", "総客数", "客単価", "指名売上", "指名数"];

function SalesManagementByTherapist() {
  const {
    arrRevenues,
    startDate,
    endDate,
    rowsPerPage,
    page,
    total,
    handleDateChange,
    handlePageChange,
    handleRowsPerPageChange,
  } = useRevenueByTherapist();
  return (
    <>
      <HeaderReservationManagement />
      <Navbar />
      <NavigationSaleMGR />
      <Container maxWidth="md" sx={{ marginTop: "45px" }}>
        <TitleComponent valueText={"セラピスト別【売上集計】"} />
      </Container>
      <Container maxWidth="lg">
        <Grid container sx={{ justifyContent: "flex-end" }}>
          <Grid item>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <DatePickerRangeComponent
                startDate={startDate}
                endDate={endDate}
                onDateChange={handleDateChange}
              />
            </div>
          </Grid>
        </Grid>
        <Box sx={{ padding: "40px 0" }}>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map((header, index) => (
                    <TableCell
                      key={index}
                      scope="col"
                      sx={{
                        "&.MuiTableCell-head": {
                          backgroundColor: "rgb(0, 0, 0)",
                          color: "rgb(255, 255, 255)",
                        },
                      }}
                      align="center"
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {arrRevenues.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:nth-of-type(2n+1)": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                      },
                    }}
                  >
                    <TableCell
                      sx={{ "&.MuiTableCell-body": { fontSize: "14px" } }}
                      align="center"
                    >
                      <Typography sx={{ fontSize: "20px" }}>
                        {row.therapist_name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ "&.MuiTableCell-body": { fontSize: "14px" } }}
                      align="center"
                    >
                      <Typography sx={{ fontSize: "20px" }}>
                        {row.total_price}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ "&.MuiTableCell-body": { fontSize: "14px" } }}
                      align="center"
                    >
                      <Typography sx={{ fontSize: "20px" }}>
                        {row.total_customer}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ "&.MuiTableCell-body": { fontSize: "14px" } }}
                      align="center"
                    >
                      <Typography sx={{ fontSize: "20px" }}>
                        {row.avg_price_per_customer}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ "&.MuiTableCell-body": { fontSize: "14px" } }}
                      align="center"
                    >
                      <Typography sx={{ fontSize: "20px" }}>
                        {row.nomination_fee}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ "&.MuiTableCell-body": { fontSize: "14px" } }}
                      align="center"
                    >
                      <Typography sx={{ fontSize: "20px" }}>
                        {row.total_nomination}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePaginationLayout
              rowsPerPage={rowsPerPage}
              total={total}
              page={page}
              rowsPerPageOptions={[50, 100, 125]}
              onPageChange={handlePageChange}
              handleChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Paper>
        </Box>
      </Container>
    </>
  );
}

export default SalesManagementByTherapist;
