import { sendGet } from "./axios";

export const getRevenueByStore = (params) =>
  sendGet("/revenue/by-store", params);

export const getRevenueByTherapist = (params) =>
  sendGet("/revenue/by-therapist", params);

export const getRevenueByIntroducer = (params) =>
  sendGet("/revenue/by-introducer", params);

export const getListDailyReport = (params) => sendGet("/daily-report", params);

export const getListDailyReportByTherapist = (params) =>
  sendGet("/daily-report/by-therapist", params);
