import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

const FormFieldSelect = ({ control, errors, label, name, options = [] }) => {
  return (
    <FormControl fullWidth variant="standard">
      <InputLabel shrink>{label || name}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Select {...field} displayEmpty>
            <MenuItem value=""></MenuItem>
            {options.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {errors[name] && (
        <FormHelperText sx={{ color: "red" }}>
          {errors[name].message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormFieldSelect;
