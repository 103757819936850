import {
  Box,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Button,
  styled,
  MenuItem,
  Select,
  Switch,
  FormControlLabel,
  FormControl,
  FormLabel,
  InputBase,
  FormHelperText,
} from "@mui/material";
import HeaderReservationManagement from "../../layouts/HeaderReservationManagement";
import Navbar from "../../layouts/Navbar";
import { useCallback } from "react";
import TablePaginationLayout from "../../layouts/TablePagination";
import useTherapistMaster from "../../features/therapist";
import PopupComponent from "../../layouts/Popup";
import DeleteIcon from "@mui/icons-material/Delete";
import { MAIN_NOMINATION_COUNT_FORMULA } from "../../constants";

const columnTitles = [
  "id",
  "日時",
  "時間",
  "名前",
  "指名料",
  "本指名料",
  "紹介者",
  "メモ",
  "操作",
];

const CustomButtonEdit = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.success.main,
  color: theme.palette.success.main,
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: theme.palette.success.light,
    borderColor: theme.palette.success.main,
  },
}));

const CustomButtonDelete = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.error.main,
  color: theme.palette.error.main,
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: theme.palette.error.light,
    borderColor: theme.palette.error.main,
  },
}));

const CustomSaveButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.success.main,
  color: theme.palette.success.main,
  backgroundColor: "transparent",
  width: "100%",
  "&:hover": {
    backgroundColor: theme.palette.success.light,
    borderColor: theme.palette.success.main,
  },
}));

const TherapistMaster = () => {
  const {
    activeSortColumn,
    sortColumnDirection,
    sortedData,
    toggle,
    newTherapist,
    rowsPerPage,
    total,
    page,
    editTherapist,
    isPopupOpenDelete,
    isPopupOpenUpdate,
    therapistToDelete,
    focusState,
    nameInputRef,
    arrIntroducers,
    errors,
    errorsUpdate,
    handleRowsPerPageChange,
    handlePageChange,
    handleSort,
    handleInputChange,
    handleAddTherapist,
    handleToggleChange,
    handleToggle,
    handleUpdateTherapist,
    handleDeleteTherapist,
    handleFocus,
    handleBlur,
    handleClosePopup,
    handleChange,
    handleEditClick,
    handleDeleteClick,
    setIsPopupOpenDelete,
  } = useTherapistMaster();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const handleSuccess = await handleUpdateTherapist(editTherapist);
      if (handleSuccess) {
        handleClosePopup();
      }
    } catch (error) {
      alert("エラーが発生しました。");
    }
  };

  const handleSubmitDelete = useCallback(async () => {
    await handleDeleteTherapist(therapistToDelete.id);
    setIsPopupOpenDelete(false);
  }, [therapistToDelete, handleDeleteTherapist, setIsPopupOpenDelete]);

  return (
    <>
      <HeaderReservationManagement />
      <Navbar />
      {isPopupOpenUpdate && (
        <PopupComponent open={isPopupOpenUpdate} onClose={handleClosePopup}>
          <FormControl
            className="MuiFormControl-marginNormal"
            sx={{ margin: "16px 0 8px", width: "100%" }}
            error={!!errorsUpdate.name}
          >
            <FormLabel
              className="MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-colorPrimary"
              htmlFor="name"
              id="name-label"
              sx={{
                position: "absolute",
                display: "block",
                transformOrigin: "left top",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
                top: 0,
                left: 0,
                color: "rgba(0, 0, 0, 0.6)",
                transform:
                  focusState.name || editTherapist.name
                    ? "translate(0px, -1.5px) scale(0.75)"
                    : "translate(0px, 20px) scale(1)",
                transition:
                  "color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
              }}
            >
              店舗名 *
            </FormLabel>
            <InputBase
              className="MuiInput-root MuiInput-underline"
              id="name"
              name="name"
              type="text"
              onFocus={() => handleFocus("name")}
              onBlur={() => handleBlur("name")}
              inputProps={{ className: "MuiInputBase-input css-mnn31" }}
              aria-invalid="false"
              value={editTherapist.name}
              onChange={handleChange}
              sx={{
                marginTop: "16px",
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before":
                {
                  borderBottom: "1px solid rgb(0, 0, 0,0.42)",
                  left: 0,
                  bottom: 0,
                  content: '""',
                  position: "absolute",
                  right: 0,
                  transition:
                    "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  pointerEvents: "none",
                },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover::before":
                {
                  borderBottom: "2px solid rgb(0, 0, 0)",
                },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-focused::after":
                {
                  borderBottom: "2px solid rgb(25, 118, 210)",
                  left: 0,
                  bottom: 0,
                  content: '""',
                  position: "absolute",
                  right: 0,
                  transform: "translateX(0px) scaleX(1)",
                  transition:
                    "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                  pointerEvents: "none",
                },
              }}
              inputRef={nameInputRef}
            />
            <FormHelperText>{errorsUpdate.name}</FormHelperText>
          </FormControl>

          <FormControl
            className="MuiFormControl-marginNormal"
            sx={{ margin: "16px 0 8px", width: "100%" }}
            error={!!errorsUpdate.nomination_fee}
          >
            <FormLabel
              className="MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-colorPrimary"
              htmlFor="nomination_fee"
              id="nomination_fee-label"
              sx={{
                position: "absolute",
                display: "block",
                transformOrigin: "left top",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
                top: 0,
                left: 0,
                color: "rgba(0, 0, 0, 0.6)",
                transform:
                  focusState.nomination_fee || editTherapist.nomination_fee
                    ? "translate(0px, -1.5px) scale(0.75)"
                    : "translate(0px, 20px) scale(1)",
                transition:
                  "color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
              }}
            >
              指名料
            </FormLabel>
            <InputBase
              className="MuiInput-root MuiInput-underline"
              id="nomination_fee"
              name="nomination_fee"
              type="number"
              onFocus={() => handleFocus("nomination_fee")}
              onBlur={() => handleBlur("nomination_fee")}
              inputProps={{ className: "MuiInputBase-input css-mnn31" }}
              aria-invalid="false"
              value={editTherapist.nomination_fee}
              onChange={handleChange}
              onKeyDown={(e) => {
                if (
                  e.key === "e" ||
                  e.key === "E" ||
                  e.key === "-" ||
                  e.key === "+" ||
                  e.key === "."
                ) {
                  e.preventDefault();
                }
              }}
              sx={{
                marginTop: "16px",
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before":
                {
                  borderBottom: "1px solid rgb(0, 0, 0,0.42)",
                  left: 0,
                  bottom: 0,
                  content: '""',
                  position: "absolute",
                  right: 0,
                  transition:
                    "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  pointerEvents: "none",
                },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover::before":
                {
                  borderBottom: "2px solid rgb(0, 0, 0)",
                },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-focused::after":
                {
                  borderBottom: "2px solid rgb(25, 118, 210)",
                  left: 0,
                  bottom: 0,
                  content: '""',
                  position: "absolute",
                  right: 0,
                  transform: "translateX(0px) scaleX(1)",
                  transition:
                    "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                  pointerEvents: "none",
                },
              }}
            />
            <FormHelperText>{errorsUpdate.nomination_fee}</FormHelperText>
          </FormControl>

          <Grid item sx={{ display: "flex", marginTop: "20px" }}>
            <FormControlLabel
              control={<Switch checked={!editTherapist.main_nomination_fee} onChange={handleToggle} />}
              label="スライド"
            />
            {!!editTherapist.main_nomination_fee && (
              <TextField
                fullWidth
                label="本指名料"
                name="main_nomination_fee"
                value={editTherapist.main_nomination_fee || ""}
                onChange={handleChange}
                required
                sx={{ marginLeft: 2 }}
                error={!!errorsUpdate.main_nomination_fee}
                helperText={errorsUpdate.main_nomination_fee}
              />
            )}
            {!editTherapist.main_nomination_fee && (
              <TextField
                fullWidth
                label="本指名料"
                value={MAIN_NOMINATION_COUNT_FORMULA}
                type="text"
                aria-readonly="true"
                sx={{ marginLeft: 2 }}
                error={!!errorsUpdate.main_nomination_fee}
                helperText={errorsUpdate.main_nomination_fee}
              />
            )}
          </Grid>

          <Select
            fullWidth
            name="introducer_id"
            sx={{ marginTop: "25px" }}
            value={editTherapist.introducer_id}
            onChange={(event) => {
              handleChange(event);
            }}
            displayEmpty
            required
            MenuProps={{
              disablePortal: true,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              PaperProps: {
                style: {
                  transform: "translateY(50px) translateX(40px)",
                },
              },
            }}
          >
            <MenuItem value="">
              <em>選択してください</em>
            </MenuItem>
            {arrIntroducers.map((option, index) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>

          <FormControl
            sx={{ width: "100%" }}
            error={!!errorsUpdate.remarks}
          >
            <textarea
              aria-label="minimum height"
              id="edit_remarks"
              name="remarks"
              placeholder="メモ"
              style={{
                width: "100%",
                height: "60px",
                marginTop: 30,
                borderRadius: 5,
                fontSize: 18,
                backgroundColor: "transparent",
                outline: "none",
              }}
              value={editTherapist.remarks}
              onChange={handleChange}
            />
            <FormHelperText>{errorsUpdate.remarks}</FormHelperText>
          </FormControl>
          <Button
            type="button"
            fullWidth
            variant="outlined"
            color="success"
            style={{ marginTop: 20 }}
            onClick={handleSubmit}
          >
            保存
          </Button>
        </PopupComponent>
      )}
      {isPopupOpenDelete && (
        <PopupComponent
          open={isPopupOpenDelete}
          onClose={() => setIsPopupOpenDelete()}
        >
          <Typography variant="h4" gutterBottom>
            確認
          </Typography>
          <Typography variant="body1" paragraph>
            削除するデータを選択しています。続行しますか?
          </Typography>

          <Button
            type="button"
            fullWidth
            variant="outlined"
            startIcon={<DeleteIcon />}
            color="error"
            style={{ marginTop: "20px" }}
            onClick={handleSubmitDelete}
          >
            消す
          </Button>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setIsPopupOpenDelete(false)}
            fullWidth
            style={{ marginTop: "20px" }}
          >
            キャンセル
          </Button>
        </PopupComponent>
      )}
      <Container maxWidth="lg">
        <Typography
          component="p"
          sx={{
            fontSize: {
              xs: 20,
              md: 28,
            },
            color: "#fff",
            top: "104px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "24px",
            letterSpacing: "3px",
            margin: "80px 0px 16px",
            textDecoration: "none",
            width: "100%",
            paddingBottom: "15px",
            borderBottom: "2px solid white",
            textAlign: "left",
          }}
        >
          セラピストマスター
        </Typography>
        <Box sx={{ marginTop: "40px", maxWidth: "100%" }}>
          <Typography
            component="p"
            sx={{
              fontSize: {
                xs: 20,
                md: 28,
              },
              color: "#fff",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "normal",
              lineHeight: "24px",
              letterSpacing: "3px",
              margin: "80px 0px 16px",
              textDecoration: "none",
              width: "100%",
              textAlign: "left",
            }}
          >
            【新規登録】
          </Typography>
          <Paper
            sx={{
              padding: "16px",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <Grid container spacing={2} direction="column">
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="セラピスト名"
                  name="name"
                  value={newTherapist.name}
                  onChange={handleInputChange}
                  required
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="指名料"
                  name="nomination_fee"
                  value={newTherapist.nomination_fee}
                  onChange={handleInputChange}
                  type="number"
                  onKeyDown={(e) => {
                    if (
                      e.key === "e" ||
                      e.key === "E" ||
                      e.key === "-" ||
                      e.key === "+" ||
                      e.key === "."
                    ) {
                      e.preventDefault();
                    }
                  }}
                  required
                  error={!!errors.nomination_fee}
                  helperText={errors.nomination_fee}
                />
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  control={
                    <Switch checked={toggle} onChange={handleToggleChange} />
                  }
                  label={<span style={{ whiteSpace: "nowrap" }}>スライド</span>}
                />
                {!toggle && (
                  <TextField
                    fullWidth
                    label="本指名料"
                    name="main_nomination_fee"
                    value={newTherapist.main_nomination_fee}
                    onChange={handleInputChange}
                    required
                    type="number"
                    onKeyDown={(e) => {
                      if (
                        e.key === "e" ||
                        e.key === "E" ||
                        e.key === "-" ||
                        e.key === "+" ||
                        e.key === "."
                      ) {
                        e.preventDefault();
                      }
                    }}
                    sx={{ marginLeft: 2 }}
                    error={!!errors.main_nomination_fee}
                    helperText={errors.main_nomination_fee}
                  />
                )}
                {toggle && (
                  <TextField
                    fullWidth
                    label="本指名料"
                    value={MAIN_NOMINATION_COUNT_FORMULA}
                    type="text"
                    aria-readonly="true"
                    sx={{ marginLeft: 2 }}
                    error={!!errors.main_nomination_fee}
                    helperText={errors.main_nomination_fee}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  name="introducer_id"
                  value={newTherapist.introducer_id}
                  onChange={handleInputChange}
                  displayEmpty
                  required
                >
                  <MenuItem value="">
                    <em>紹介者を選択してください</em>
                  </MenuItem>
                  {arrIntroducers.map((option, index) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="メモ"
                  name="remarks"
                  value={newTherapist.remarks}
                  onChange={handleInputChange}
                  multiline
                  rows={4}
                  error={!!errors.remarks}
                  helperText={errors.remarks}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <CustomSaveButton
                  variant="outlined"
                  onClick={handleAddTherapist}
                >
                  保存
                </CustomSaveButton>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
      <Container maxWidth="lg">
        <Box sx={{ marginTop: "40px", maxWidth: "100%" }}>
          <Typography
            component="p"
            sx={{
              fontSize: {
                xs: 20,
                md: 28,
              },
              color: "#fff",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "normal",
              lineHeight: "24px",
              letterSpacing: "3px",
              margin: "80px 0px 16px",
              textDecoration: "none",
              width: "100%",
              textAlign: "left",
            }}
          >
            【登録済み一覧】
          </Typography>
          <Paper
            sx={{
              marginTop: "16px",
              backgroundColor: "#fff",
              boxShadow: "none",
            }}
          >
            <TableContainer>
              <Table aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow>
                    {columnTitles.map((title, index) => {
                      const isSorted = activeSortColumn === index;
                      const sortDirection = isSorted
                        ? sortColumnDirection
                        : "asc";

                      return (
                        <TableCell
                          key={index}
                          align="center"
                          sx={{ padding: "8px 16px", color: "#000" }}
                          sortDirection={isSorted ? sortDirection : false}
                        >
                          <TableSortLabel
                            active={isSorted}
                            direction={sortDirection}
                            onClick={() => handleSort(index)}
                          >
                            {title}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((row, rowIndex) => (
                    <TableRow key={rowIndex} className="MuiTableRow-hover">
                      {Object.entries(row).map(([key, value], cellIndex) => key !== 'default_main_nomination_fee' && (
                        <TableCell
                          key={cellIndex}
                          align="center"
                          sx={{ color: "#000" }}
                        >
                          {key === "main_nomination_fee"
                            ? value === null
                              ? MAIN_NOMINATION_COUNT_FORMULA
                              : value
                            : value}
                        </TableCell>
                      ))}
                      <TableCell align="center">
                        <CustomButtonEdit
                          variant="outlined"
                          onClick={() => handleEditClick(row)}
                        >
                          編集
                        </CustomButtonEdit>
                        <CustomButtonDelete
                          variant="outlined"
                          sx={{ marginLeft: 1 }}
                          onClick={() => handleDeleteClick(row)}
                        >
                          削除
                        </CustomButtonDelete>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationLayout
              rowsPerPage={rowsPerPage}
              total={total}
              page={page}
              rowsPerPageOptions={[50, 100, 125]}
              onPageChange={handlePageChange}
              handleChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default TherapistMaster;
