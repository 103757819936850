import {
  Box,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import HeaderReservationManagement from "../../layouts/HeaderReservationManagement";
import Navbar from "../../layouts/Navbar";
import TablePaginationLayout from "../../layouts/TablePagination";
import useCustomerMGR from "../../features/customers/customerMGR";
import SearchIcon from "@mui/icons-material/Search";

const columnTitles = [
  "ご利用日",
  "名前",
  "セラピスト名",
  "(OK・NG)",
  "電話番号",
];

function CustomerManagement() {
  const {
    activeSortColumn,
    sortColumnDirection,
    sortedData,
    rowsPerPage,
    page,
    total,
    isFocused,
    searchKeyword,
    handleRowsPerPageChange,
    handlePageChange,
    handleSort,
    handleTypeChange,
    handleFocus,
    handleBlur,
    handleInputchange,
    handleSearch,
  } = useCustomerMGR()

  return (
    <>
      <HeaderReservationManagement />
      <Navbar />
      <Container maxWidth="lg" sx={{}}>
        <Typography
          component="p"
          sx={{
            fontSize: {
              xs: 20,
              md: 28,
            },
            color: "#fff",
            top: "104px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "24px",
            letterSpacing: "3px",
            margin: "80px 0px 16px",
            textDecoration: "none",
            width: "100%",
            paddingBottom: "15px",
            borderBottom: "2px solid white",
            textAlign: "left",
          }}
        >
          顧客管理
        </Typography>
      </Container>
      <Container maxWidth="xl">
        <Box sx={{ marginTop: "40px" }}>
          <Grid
            container
            sx={{
              justifyContent: "flex-end",
            }}
          >
            <Grid item>
              <FormControl
                className="MuiFormControl-marginNormal"
                sx={{ margin: "16px 0 8px" }}
              >
                <InputBase
                  className="MuiInput-root MuiInput-underline "
                  id="search_key"
                  name="search_key"
                  placeholder="検索"
                  type="text"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={handleInputchange}
                  inputProps={{ className: "MuiInputBase-input css-mnn31" }}
                  aria-invalid="false"
                  value={searchKeyword}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon sx={{ cursor: 'pointer' }} onClick={handleSearch} />
                    </InputAdornment>
                  }
                  sx={{
                    marginTop: "16px",
                    "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before":
                    {
                      borderBottom: "1px solid rgb(0, 0, 0,0.42)",
                      left: 0,
                      bottom: 0,
                      content: '""',
                      position: "absolute",
                      right: 0,
                      transition:
                        "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                      pointerEvents: "none",
                    },
                    "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover::before":
                    {
                      borderBottom: "2px solid rgb(0, 0, 0)",
                    },
                    "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::after":
                    {
                      borderBottom: "2px solid rgb(25, 118, 210)",
                      left: 0,
                      bottom: 0,
                      content: '""',
                      position: "absolute",
                      right: 0,
                      transform: isFocused
                        ? "translateX(0px) scaleX(1)"
                        : "scale(0)",
                      transition:
                        "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                      pointerEvents: "none",
                    },
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Paper sx={{ marginTop: "16px" }}>
            <TableContainer>
              <Table aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow>
                    {columnTitles.map((title, index) => {
                      const isSorted = activeSortColumn === index;
                      const sortDirection = isSorted ? sortColumnDirection : 'asc';

                      return (
                        <TableCell
                          key={index}
                          align="center"
                          sx={{ padding: "8px 16px" }}
                          sortDirection={isSorted ? sortDirection : false}
                        >
                          <TableSortLabel
                            active={isSorted}
                            direction={sortDirection}
                            onClick={() => handleSort(index)}
                          >
                            {title}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((row, rowIndex) => (
                    <TableRow key={rowIndex} className="MuiTableRow-hover">
                      {Object.entries(row).map(([key, value], cellIndex) => (
                        <TableCell key={cellIndex} align="center">
                          {key === "customer_type" ? (
                            <Select
                              variant="standard"
                              value={row.customer_type}
                              onChange={(e) => handleTypeChange(e, rowIndex)}
                            >
                              <MenuItem value={1}>OK</MenuItem>
                              <MenuItem value={2}>NG</MenuItem>
                            </Select>
                          ) : (
                            value
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationLayout
              rowsPerPage={rowsPerPage}
              total={total}
              page={page}
              rowsPerPageOptions={[50, 100, 125]}
              onPageChange={handlePageChange}
              handleChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Paper>
        </Box>
      </Container>
    </>
  );
}

export default CustomerManagement;
