import { useEffect, useRef, useState } from "react";
import {
  createCourse,
  deleteCourse,
  getListCourse,
  updateCourse,
} from "../../api/course";
import { useQuery } from "react-query";

const useCourseMaster = () => {
  const [arrCourses, setArrCourses] = useState([]);
  const [activeSortColumn, setActiveSortColumn] = useState(0);
  const [sortColumnDirection, setSortColumnDirection] = useState("asc");
  const [sortedData, setSortedData] = useState([]);
  const [newCourse, setNewCourse] = useState({
    name: "",
    cost: "",
    therapist_amount: "",
    duration: "",
    remarks: "",
  });

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const [editCourse, setEditCourse] = useState(null);
  const [courseToDelete, setCourseToDelete] = useState(null);
  const [isPopupOpenUpdate, setIsPopupOpenUpdate] = useState(false);
  const [isPopupOpenDelete, setIsPopupOpenDelete] = useState(false);
  const [focusState, setFocusState] = useState({
    name: false,
    cost: false,
    therapist_amount: false,
    duration: false,
    remarks: false,
  });

  const nameInputRef = useRef(null);

  const [errors, setErrors] = useState({
    name: "",
    cost: "",
    therapist_amount: "",
    duration: "",
    remarks: "",
  });

  const [errorsUpdate, setErrorsUpdate] = useState({
    name: "",
    cost: "",
    therapist_amount: "",
    duration: "",
    remarks: "",
  });

  const { data, refetch } = useQuery(
    ["courses", page, rowsPerPage],
    () => {
      const params = {
        page: page + 1,
        per_page: rowsPerPage,
      };
      return getListCourse(params);
    },
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (data) {
      setArrCourses(transformCourseData(data.data.data));
      setTotal(data.data.total);
    }
  }, [data]);

  useEffect(() => {
    setSortedData(arrCourses);
  }, [arrCourses]);

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  const transformCourseData = (data) => {
    return data.map((course) => ({
      id: course.id.toString(),
      name: course.name,
      cost: parseInt(course.cost),
      therapist_amount: parseInt(course.therapist_amount),
      duration: course.duration,
      remarks: course.remarks || "",
    }));
  };

  const handleSort = (columnIndex) => {
    const isSorted = activeSortColumn === columnIndex;
    const direction = isSorted
      ? sortColumnDirection === "asc"
        ? "desc"
        : "asc"
      : "asc";
    const sorted = [...sortedData].sort((a, b) => {
      const aValue = a[Object.keys(a)[columnIndex]];
      const bValue = b[Object.keys(b)[columnIndex]];

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setSortedData(sorted);
    setActiveSortColumn(columnIndex);
    setSortColumnDirection(direction);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCourse({ ...newCourse, [name]: value });
    setErrors({ name: "", cost: "", remarks: "" });
  };

  const handleAddCourse = async () => {
    try {
      const newCourseData = {
        name: newCourse.name,
        cost: newCourse.cost,
        therapist_amount: newCourse.therapist_amount,
        duration: newCourse.duration,
        remarks: newCourse.remarks,
      };

      const response = await createCourse(newCourseData);

      if (response && response.data) {
        refetch();
        setNewCourse({ name: "", cost: "", therapist_amount: "", duration: "", remarks: "" });
        setErrors({ name: "", cost: "", therapist_amount: "", remarks: "",  });
      } else {
        alert("エラーが発生しました。");
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 422) {
        const fieldErrors = error.response.data.message;
        setErrors((prev) => ({
          ...prev,
          ...fieldErrors,
        }));
      }
      else {
        alert("エラーが発生しました。");
      }
    }
  };

  const handleUpdateCourse = async (updatedCourse) => {
    try {
      const { id, name, cost, therapist_amount, duration, remarks } = updatedCourse;
      const updatedData = {
        name,
        cost: cost,
        therapist_amount: therapist_amount,
        duration: duration,
        remarks: remarks,
      };

      const response = await updateCourse(id, updatedData);

      if (response && response.data) {
        refetch();
        return true;
      } else {
        alert("エラーが発生しました。");
        return false;
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const fieldErrors = error.response.data.message;
        setErrorsUpdate((prev) => ({
          ...prev,
          ...fieldErrors,
        }));
      }
      else {
        alert("エラーが発生しました。");
      }
      return false;
    }
  };

  const handleDeleteCourse = async (id) => {
    try {
      const response = await deleteCourse(id);

      if (response.success) {
        refetch();
      } else {
        alert("エラーが発生しました。");
      }
    } catch (error) {
      alert("エラーが発生しました。");
    }
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    const maxPage = Math.floor(total / newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(Math.min(page, maxPage));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleFocus = (field) => {
    setFocusState((prevState) => ({
      ...prevState,
      [field]: true,
    }));
  };

  const handleBlur = (field) => {
    setFocusState((prevState) => ({
      ...prevState,
      [field]: false,
    }));
  };

  const handleEditClick = (course) => {
    setEditCourse(course);
    setIsPopupOpenUpdate(true);
  };

  const handleDeleteClick = (course) => {
    setCourseToDelete(course);
    setIsPopupOpenDelete(true);
  }

  const handleClosePopup = () => {
    setIsPopupOpenUpdate(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditCourse((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrorsUpdate((prev) => ({
     ...prev,
      [name]: "",
    }));
  };

  return {
    arrCourses,
    activeSortColumn,
    sortColumnDirection,
    sortedData,
    newCourse,
    rowsPerPage,
    page,
    total,
    editCourse,
    courseToDelete,
    isPopupOpenUpdate,
    isPopupOpenDelete,
    focusState,
    nameInputRef,
    errors,
    errorsUpdate,
    setIsPopupOpenDelete,
    handleRowsPerPageChange,
    handlePageChange,
    handleSort,
    handleInputChange,
    handleAddCourse,
    handleUpdateCourse,
    handleDeleteCourse,
    handleFocus,
    handleBlur,
    handleEditClick,
    handleDeleteClick,
    handleClosePopup,
    handleChange
  };
};

export default useCourseMaster;
