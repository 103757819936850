import { useCallback, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import {
  createTherapist,
  deleteTherapist,
  getListTherapist,
  updateTherapist,
} from "../../api/therapist";
import { getListIntroducer } from "../../api/introducer";

const useTherapistMaster = () => {
  const [arrTherapists, setArrTherapists] = useState([]);
  const [activeSortColumn, setActiveSortColumn] = useState(0);
  const [sortColumnDirection, setSortColumnDirection] = useState("asc");
  const [sortedData, setSortedData] = useState([]);
  const [newTherapist, setNewTherapist] = useState({
    name: "",
    nomination_fee: "",
    main_nomination_fee: "1000",
    introducer_id: "",
    remarks: "",
  });
  const [toggle, setToggle] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const [editTherapist, setEditTherapist] = useState({
    name: "",
    nomination_fee: "",
    main_nomination_fee: "",
    default_main_nomination_fee: "",
    introducer_id: "",
    remarks: "",
  });
  const [isPopupOpenDelete, setIsPopupOpenDelete] = useState(false);
  const [isPopupOpenUpdate, setIsPopupOpenUpdate] = useState(false);
  const [therapistToDelete, setTherapistToDelete] = useState(null);
  const [focusState, setFocusState] = useState({
    name: false,
    nomination_fee: false,
    main_nomination_fee: false,
  });
  const [arrIntroducers, setArrIntroducers] = useState([]);

  const nameInputRef = useRef(null);

  const [errors, setErrors] = useState({
    name: "",
    nomination_fee: "",
    main_nomination_fee: "",
    remarks: "",
  });

  const [errorsUpdate, setErrorsUpdate] = useState({
    name: "",
    nomination_fee: "",
    main_nomination_fee: "",
    remarks: "",
  });

  const { data: therapistsData, refetch: refetchTherapists } = useQuery(
    ["therapists", page, rowsPerPage],
    () => {
      const params = {
        page: page + 1,
        per_page: rowsPerPage,
      };
      return getListTherapist(params);
    },
    {
      keepPreviousData: true,
    }
  );

  const { data: introducersData } = useQuery(
    ["introducers", page, rowsPerPage],
    () => {
      const params = {
        page: page + 1,
        per_page: rowsPerPage,
      };
      return getListIntroducer(params);
    },
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (therapistsData) {
      setArrTherapists(transformTherapistData(therapistsData.data.data));
      setTotal(therapistsData.data.total);
    }
  }, [therapistsData]);

  useEffect(() => {
    if (introducersData) {
      setArrIntroducers(transformIntroducerData(introducersData.data.data));
    }
  }, [introducersData]);

  useEffect(() => {
    setSortedData(arrTherapists);
  }, [arrTherapists]);

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  const transformIntroducerData = (data) => {
    return data.map((introducer) => {
      return {
        id: introducer.id.toString(),
        name: introducer.name,
      };
    });
  };

  const transformTherapistData = (data) => {
    return data.map((therapist) => ({
      id: therapist.id.toString(),
      date: new Date(therapist.created_at).toISOString().split("T")[0],
      time: new Date(therapist.created_at).toLocaleTimeString("ja-JP", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      }),
      name: therapist.name,
      nomination_fee: parseInt(therapist.nomination_fee),
      main_nomination_fee: therapist.main_nomination_fee ? parseInt(therapist.main_nomination_fee) : null,
      introducer_id: therapist?.introducer?.name || "",
      remarks: therapist.remarks || "",
      default_main_nomination_fee: parseInt(therapist.default_main_nomination_fee) || 1000,
    }));
  };

  const handleSort = (columnIndex) => {
    const isSorted = activeSortColumn === columnIndex;
    let direction = isSorted
      ? sortColumnDirection === "asc"
        ? "desc"
        : "asc"
      : "asc";
    sortedData.sort((a, b) => {
      const aValue = a[Object.keys(a)[columnIndex]];
      const bValue = b[Object.keys(b)[columnIndex]];

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setSortedData([...sortedData]);
    setActiveSortColumn(columnIndex);
    setSortColumnDirection(direction);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTherapist({ ...newTherapist, [name]: value });
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleAddTherapist = async () => {
    try {
      const newTherapistData = {
        name: newTherapist.name,
        nomination_fee: newTherapist.nomination_fee,
        main_nomination_fee: newTherapist.main_nomination_fee,
        introducer_id: newTherapist.introducer_id,
        remarks: newTherapist.remarks,
      };
      const response = await createTherapist(newTherapistData);

      if (response && response.data) {
        refetchTherapists();
        setNewTherapist({ name: "", nomination_fee: "", main_nomination_fee: "", remarks: "" });
        setErrors({ name: "", nomination_fee: "", main_nomination_fee: "", remarks: "" });
      } else {
        alert("エラーが発生しました。");
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const fieldErrors = error.response.data.message;
        setErrors((prev) => ({
          ...prev,
          ...fieldErrors,
        }));
      }
      else {
        alert("エラーが発生しました。");
      }
    }
  };

  const handleUpdateTherapist = async (updatedTherapist) => {
    try {
      const { id, name, nomination_fee, main_nomination_fee, introducer_id, remarks } =
        updatedTherapist;
      const updatedData = {
        name: name,
        nomination_fee: nomination_fee,
        main_nomination_fee: main_nomination_fee,
        introducer_id: introducer_id,
        remarks: remarks,
      };
      const response = await updateTherapist(id, updatedData);

      if (response && response.data) {
        refetchTherapists();
        return true
      } else {
        alert("エラーが発生しました。");
        return false
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const fieldErrors = error.response.data.message;
        setErrorsUpdate((prev) => ({
          ...prev,
          ...fieldErrors,
        }));
      }
      else {
        alert("エラーが発生しました。");
      }
      return false
    }
  };

  const handleDeleteTherapist = async (id) => {
    try {
      const response = await deleteTherapist(id);

      if (response.success) {
        refetchTherapists();
      } else {
        alert("エラーが発生しました。");
      }
    } catch (error) {
      alert("エラーが発生しました。");
    }
  };

  const handleToggleChange = useCallback((e) => {
    const isChecked = e.target.checked;

    setToggle(isChecked);
    if (!isChecked) {
      setNewTherapist({ ...newTherapist, main_nomination_fee: 1000 });
    } else {
      setNewTherapist({ ...newTherapist, main_nomination_fee: "" });
    }
  }, [newTherapist]);

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    const maxPage = Math.floor(total / newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(Math.min(page, maxPage));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleFocus = (field) => {
    setFocusState((prevState) => ({
      ...prevState,
      [field]: true,
    }));
  };

  const handleBlur = (field) => {
    setFocusState((prevState) => ({
      ...prevState,
      [field]: false,
    }));
  };

  const handleClosePopup = () => {
    setIsPopupOpenUpdate(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditTherapist((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrorsUpdate((prev) => ({ ...prev, [name]: "" }));
  };

  const handleToggle = useCallback((e) => {
    const isChecked = e.target.checked;
    if (!isChecked) {
      setEditTherapist({ ...editTherapist, main_nomination_fee: editTherapist.default_main_nomination_fee });
    } else {
      setEditTherapist({ ...editTherapist, main_nomination_fee: "" });
    }
  }, [editTherapist]);

  const handleEditClick = (therapist) => {
    const getIntroducerId = (feeValue) => {
      const option = arrIntroducers.find((o) => o.name === feeValue);
      return option ? option.id : "";
    };
    const introducerId = getIntroducerId(therapist.introducer_id);
    const updatedTherapist = {
      ...therapist,
      introducer_id: introducerId,
    };
    setEditTherapist(updatedTherapist);
    setIsPopupOpenUpdate(true);
  };

  const handleDeleteClick = (therapist) => {
    setTherapistToDelete(therapist);
    setIsPopupOpenDelete(true);
  };

  return {
    arrTherapists,
    activeSortColumn,
    sortColumnDirection,
    sortedData,
    newTherapist,
    toggle,
    rowsPerPage,
    page,
    total,
    editTherapist,
    isPopupOpenDelete,
    isPopupOpenUpdate,
    therapistToDelete,
    focusState,
    nameInputRef,
    arrIntroducers,
    errorsUpdate,
    errors,
    handleRowsPerPageChange,
    handlePageChange,
    handleSort,
    handleInputChange,
    handleAddTherapist,
    handleUpdateTherapist,
    handleDeleteTherapist,
    handleToggleChange,
    handleToggle,
    handleFocus,
    handleBlur,
    handleClosePopup,
    handleChange,
    handleEditClick,
    handleDeleteClick,
    setIsPopupOpenDelete,
  };
};

export default useTherapistMaster;
