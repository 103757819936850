import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import styled from "./style.module.css";
import dayjs from "dayjs";
import DatePickerComponent from "../../components/DatePicker";
import MonthlyReverseCountComponent from "../../components/MonthlyReverseCount";
import { useQuery } from "react-query";
import usePaginate from "../../hooks/usePaginate";
import {
  getListDailyReport,
  getListDailyReportByTherapist,
} from "../../api/dailyReport";
import TablePaginationLayout from "../../layouts/TablePagination";
import { getCountOrderNominatation, getValueBoolean } from "../../utils";
import { STALE_TIME_DEFAULT } from "../../constants";

const styleTableHead = {
  backgroundColor: "#000",
  color: "#fff",
  width: "100px",
  display: "block",
  textAlign: "center",
  verticalAlign: "middle",
  padding: "auto",
  paddingLeft: 0,
  paddingRight: 0,
};

const styleTableBody = {
  ...styleTableHead,
  color: "#000",
  backgroundColor: "transparent",
};

const dailyReportFields = [
  { label: "エリア", value: "store_name" },
  { label: "時間", value: "time" },
  { label: "セラピスト", value: "therapist_name" },
  { label: "新規/リピーター", value: "new_or_repeat" },
  { label: "お客様名", value: "customer_name" },
  { label: "オプション", value: "option_cost" },

  {
    label: "オプション電子マネー使用",
    value: "option_electronic_money",
    type: "boolean",
  },
  {
    label: "オプションカード使用",
    value: "option_card_usage",
    type: "boolean",
  },
  { label: "コース", value: "course_cost" },
  {
    label: "コース電子マネー使用",
    value: "course_electronic_money",
    type: "boolean",
  },
  { label: "コースカード使用", value: "course_card_usage", type: "boolean" },

  { label: "指名", value: "nomination_fee" },
  { label: "延長", value: "extension_cost" },
  {
    label: "延長電子マネー使用",
    value: "extension_electronic_money",
    type: "boolean",
  },
  { label: "延長カード使用", value: "extension_card_usage", type: "boolean" },
  { label: "お支払い額", value: "total_cost" },
  { label: "セラピスト報酬", value: "therapist_amount" },
  { label: "SB", value: "introducer_amount" },
  { label: "利益", value: "total_profit" },
];

const dailyReportTherapist1Fields = [
  { label: "セラピスト", value: "therapist_name" },
  { label: "場所", value: "store_name" },
  { label: "待機時間", value: "waiting_time" },
];

const dailyReportTherapist2Fields = [
  { label: "合計", value: "total_nominations" },
  { label: "セラピスト報酬", value: "total_amount_paid" },
  { label: "未払い金", value: "unpaid_amount" },
  { label: "封筒入金額", value: "envelope_deposit_amount" },
  { label: "封筒チェック", value: "envelope_check" },
  { label: "備考", value: "remarks" },
];

const DailyReport = ({ dataNominatation }) => {
  const [dateSelected, setDateSelected] = useState(dayjs(new Date()));
  const [activeDayIndex, setActiveDayIndex] = useState(null);

  const [dailyReportList, setDailyReportList] = useState([]);
  const { page, perPage, total, changePage, setTotal, changePerPage } =
    usePaginate(0, 10);

  const [dailyReportTherapistList, setDailyReportTherapistList] = useState([]);
  const {
    page: pageTherapist,
    perPage: perPageTherapist,
    total: totalTherapist,
    changePage: changePageTherapist,
    setTotal: setTotalTherapist,
    changePerPage: changePerPageTherapist,
  } = usePaginate(0, 10);

  const daysInMonth = useMemo(() => {
    const currentMonth = dayjs().month();
    const currentYear = dayjs().year();
    const daysInCurrentMonth = dayjs(
      `${currentYear}-${currentMonth + 1}-01`
    ).daysInMonth();
    const daysArray = [];
    for (let i = 1; i <= daysInCurrentMonth; i++) {
      const date = dayjs(`${currentYear}-${currentMonth + 1}-${i}`).format(
        "YYYY-MM-DD"
      );
      daysArray.push({
        dayOfWeek: i,
        isActive: false,
        reserveCount: 0,
        date: i,
        dateFormat: date,
      });
    }

    return daysArray;
  }, []);

  const updateCurrentDate = (newDate) => {
    const updatedDate = dateSelected.set("date", newDate);
    setDateSelected(updatedDate);
  };

  const { data: dataDailyReport } = useQuery(
    ["list_daily_report", dateSelected, page],
    () => {
      return getListDailyReport({
        date: dateSelected.format("YYYY-MM-DD"),
        per_page: perPage,
        page: page + 1,
      });
    },
    {
      keepPreviousData: true,
      staleTime: STALE_TIME_DEFAULT,
    }
  );

  const { data: dataDailyReportTherapist } = useQuery(
    ["list_daily_report_therapist", dateSelected, pageTherapist],
    () => {
      return getListDailyReportByTherapist({
        date: dateSelected.format("YYYY-MM-DD"),
        per_page: perPageTherapist,
        page: pageTherapist + 1,
      });
    },
    {
      keepPreviousData: true,
      staleTime: STALE_TIME_DEFAULT,
    }
  );

  useEffect(() => {
    try {
      if (dataDailyReport) {
        setDailyReportList(dataDailyReport?.data?.data);
        setTotal(dataDailyReport?.data?.total);
      }
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDailyReport]);

  useEffect(() => {
    try {
      if (dataDailyReportTherapist) {
        setDailyReportTherapistList(dataDailyReportTherapist?.data?.data);
        setTotalTherapist(dataDailyReportTherapist?.data?.total);
      }
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDailyReportTherapist]);

  useEffect(() => {
    const todayIndex = daysInMonth.findIndex(
      (day) => parseInt(day.date) === dateSelected.date()
    );
    if (todayIndex !== -1) {
      setActiveDayIndex(todayIndex);
    }
  }, [dateSelected, daysInMonth]);

  return (
    <>
      <Container maxWidth="lg">
        <div className={`${styled.noteWrapper}`}>
          <Typography
            sx={{
              fontSize: {
                md: 18,
                xs: 10,
              },
              letterSpacing: {
                md: "3px",
                xs: 0,
              },
              color: "rgb(255, 6, 6)",
              textDecoration: "none",
              marginTop: "80px",
              textAlign: "center",
              width: "100%",
              paddingBottom: "15px",
              fontWeight: "normal",
            }}
          >
            ※当日のデータは、翌AM6時間に更新されます。
          </Typography>
        </div>
        <Typography
          sx={{
            fontSize: "28px",
            color: "#fff",
            top: "104px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "24px",
            letterSpacing: "3px",
            margin: "80px 0 16px",
            textDecoration: "none",
            width: "100%",
            paddingBottom: "15px",
            borderBottom: "2px solid white",
            textAlign: "left",
          }}
        >
          日報
        </Typography>
      </Container>

      <Container
        // maxWidth="xl"
        sx={{
          padding: "0px !important",
        }}
      >
        <Box
          sx={{
            marginTop: "40px",
            paddingRight: "16px",
            paddingLeft: "16px",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={4} sx={{ flexGrow: 1 }}>
              <Button
                variant="outlined"
                onClick={() =>
                  setDateSelected((prev) => prev.subtract(1, "day"))
                }
              >
                前日
              </Button>
            </Grid>
            <Grid item xs={4} sx={{ flexGrow: 1 }}>
              <FormControl>
                <DatePickerComponent
                  currentDate={dateSelected}
                  onDateChange={setDateSelected}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} sx={{ flexGrow: 1 }}>
              <Button
                variant="outlined"
                onClick={() => setDateSelected((prev) => prev.add(1, "day"))}
              >
                翌日
              </Button>
            </Grid>
          </Grid>
        </Box>
        <MonthlyReverseCountComponent
          days={daysInMonth}
          currentDate={dateSelected}
          updateCurrentDate={updateCurrentDate}
          activeDayIndex={activeDayIndex}
          setActiveDayIndex={setActiveDayIndex}
          type={"dailyReport"}
        />
        <Box sx={{ margin: "40px 0" }}>
          <Paper
            sx={{
              backgroundColor: "rgb(255, 255, 255)",
              color: "rgba(0, 0, 0, 0.87)",
              transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              borderRadius: "4px",
              boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
              width: "100%",
              marginBottom: "16px",
              overflow: "auto",
            }}
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow sx={{ display: "flex" }}>
                    {dailyReportFields.map((field) => (
                      <TableCell key={field.value} sx={styleTableHead}>
                        {field.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dailyReportList.map((item) => (
                    <TableRow
                      key={item.store_name + item.therapist_name}
                      sx={{ display: "flex" }}
                    >
                      {dailyReportFields.map((field) => (
                        <TableCell key={field.value} sx={styleTableBody}>
                          {field.type === "boolean"
                            ? getValueBoolean(item[field.value])
                            : item[field.value]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationLayout
              rowsPerPage={perPage}
              total={total}
              page={page}
              rowsPerPageOptions={[10]}
              onPageChange={changePage}
              handleChangeRowsPerPage={changePerPage}
            />
          </Paper>
        </Box>
      </Container>

      <Container maxWidth="lg">
        <Typography
          sx={{
            fontSize: "28px",
            color: "#fff",
            top: "104px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "24px",
            letterSpacing: "3px",
            margin: "80px 0 16px",
            textDecoration: "none",
            width: "100%",
            paddingBottom: "15px",
            borderBottom: "2px solid white",
            textAlign: "left",
          }}
        >
          セラピスト集計
        </Typography>
      </Container>

      <Container
        sx={{
          padding: "0px !important",
        }}
      >
        <Box sx={{ margin: "40px 0" }}>
          <Paper
            sx={{
              backgroundColor: "rgb(255, 255, 255)",
              color: "rgba(0, 0, 0, 0.87)",
              transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              borderRadius: "4px",
              boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
              width: "100%",
              marginBottom: "16px",
              overflow: "auto",
            }}
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow sx={{ display: "flex" }}>
                    {dailyReportTherapist1Fields.map((field) => (
                      <TableCell key={field.value} sx={styleTableHead}>
                        {field.label}
                      </TableCell>
                    ))}

                    {dataNominatation.map((item) => (
                      <TableCell key={item.id} sx={styleTableHead}>
                        {item.name}
                      </TableCell>
                    ))}

                    {dailyReportTherapist2Fields.map((field) => (
                      <TableCell key={field.value} sx={styleTableHead}>
                        {field.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dailyReportTherapistList.map((item) => (
                    <TableRow
                      key={item.store_name + item.therapist_name}
                      sx={{ display: "flex" }}
                    >
                      {dailyReportTherapist1Fields.map((field) => (
                        <TableCell key={field.value} sx={styleTableBody}>
                          {field.type === "boolean"
                            ? getValueBoolean(item[field.value])
                            : item[field.value]}
                        </TableCell>
                      ))}

                      {dataNominatation.map((itemNomination) => (
                        <TableCell key={item.id} sx={styleTableBody}>
                          {getCountOrderNominatation(
                            item.nominations || [],
                            itemNomination.id
                          )}
                        </TableCell>
                      ))}

                      {dailyReportTherapist2Fields.map((field) => (
                        <TableCell key={field.value} sx={styleTableBody}>
                          {field.type === "boolean"
                            ? getValueBoolean(item[field.value])
                            : item[field.value]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationLayout
              rowsPerPage={perPageTherapist}
              total={totalTherapist}
              page={pageTherapist}
              rowsPerPageOptions={[10]}
              onPageChange={changePageTherapist}
              handleChangeRowsPerPage={changePerPageTherapist}
            />
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default DailyReport;
