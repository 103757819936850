import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import {
  createNomination,
  deleteNomination,
  getListNomination,
  updateNomination,
} from "../../api/nomination";

const useNominationMaster = () => {
  const [arrNominations, setArrNominations] = useState([]);
  const [activeSortColumn, setActiveSortColumn] = useState(0);
  const [sortColumnDirection, setSortColumnDirection] = useState("asc");
  const [sortedData, setSortedData] = useState([]);
  const [newNomination, setNewNomination] = useState({
    name: "",
    cost: "",
    therapist_amount: "",
    status: 2,
    remarks: "",
  });
  const [toggle, setToggle] = useState(true);

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const [editNomination, setEditNomination] = useState({
    name: "",
    cost: "",
    therapist_amount: "",
    status: "",
    remarks: "",
  });
  const [isPopupOpenDelete, setIsPopupOpenDelete] = useState(false);
  const [isPopupOpenUpdate, setIsPopupOpenUpdate] = useState(false);
  const [nominationToDelete, setNominationToDelete] = useState(null);
  const [focusState, setFocusState] = useState({
    name: false,
    cost: false,
    therapist_amount: false,
  });

  const nameInputRef = useRef(null);

  const [errors, setErrors] = useState({
    name: "",
    cost: "",
    therapist_amount: "",
    remarks: "",
  });

  const [errorsUpdate, setErrorsUpdate] = useState({
    name: "",
    cost: "",
    therapist_amount: "",
    remarks: "",
  });

  const { data: nominationsData, refetch: refetchNominations } = useQuery(
    ["nominations", page, rowsPerPage],
    () => {
      const params = {
        page: page + 1,
        per_page: rowsPerPage,
      };
      return getListNomination(params);
    },
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (nominationsData) {
      console.log(nominationsData);
      setArrNominations(transformNominationData(nominationsData.data.data));
      setTotal(nominationsData.data.total);
    }
  }, [nominationsData]);

  useEffect(() => {
    setSortedData(arrNominations);
  }, [arrNominations]);

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  const transformNominationData = (data) => {
    return data.map((nomination) => ({
      id: nomination.id.toString(),
      name: nomination.name,
      cost: parseInt(nomination.cost),
      therapist_amount: nomination.therapist_amount ? parseInt(nomination.therapist_amount) || "" : '1,000円～本指名５本ごとに500円アップ',
      remarks: nomination.remarks || "",
    }));
  };

  const handleSort = (columnIndex) => {
    const isSorted = activeSortColumn === columnIndex;
    let direction = isSorted
      ? sortColumnDirection === "asc"
        ? "desc"
        : "asc"
      : "asc";
    sortedData.sort((a, b) => {
      const aValue = a[Object.keys(a)[columnIndex]];
      const bValue = b[Object.keys(b)[columnIndex]];

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setSortedData([...sortedData]);
    setActiveSortColumn(columnIndex);
    setSortColumnDirection(direction);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewNomination({ ...newNomination, [name]: value });
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleAddNomination = async () => {
    try {
      const newNominationData = {
        name: newNomination.name,
        cost: newNomination.cost,
        therapist_amount: newNomination.therapist_amount,
        remarks: newNomination.remarks,
        status: newNomination.status,
      };

      const response = await createNomination(newNominationData);
      
      if (response && response.data) {
        refetchNominations();
        setNewNomination({ name: "", cost: "", therapist_amount: "", remarks: "" });
        setErrors({ name: "", cost: "", therapist_amount: "", remarks: "" });
      } else {
        alert("エラーが発生しました。");
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const fieldErrors = error.response.data.message;
        setErrors((prev) => ({
          ...prev,
          ...fieldErrors,
        }));
      }
      else {
        alert("エラーが発生しました。");
      }
    }
  };

  const handleUpdateNomination = async (updatedNomination) => {
    try {
      const { id, name, cost, therapist_amount, status, remarks } =
        updatedNomination;
      const updatedData = {
        name: name,
        cost: cost,
        therapist_amount: therapist_amount,
        status: status,
        remarks: remarks,
      };
      const response = await updateNomination(id, updatedData);

      if (response && response.data) {
        refetchNominations(); 
        return true
      } else {
        alert("エラーが発生しました。");
        return false
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const fieldErrors = error.response.data.message;
        setErrorsUpdate((prev) => ({
          ...prev,
          ...fieldErrors,
        }));
      }
      else {
        alert("エラーが発生しました。");
      }
      return false
    }
  };

  const handleDeleteNomination = async (id) => {
    try {
      const response = await deleteNomination(id);

      if (response.success) {
        refetchNominations();
      } else {
        alert("エラーが発生しました。");
      }
    } catch (error) {
      alert("エラーが発生しました。");
    }
  };

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const convertedValue = isChecked ? 2 : 1;

    setToggle(isChecked);
    if (!isChecked) {
      setNewNomination({ ...newNomination,status:convertedValue, therapist_amount: "" });
    }
    else
    {
      setNewNomination({...newNomination, status: convertedValue });
    }
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    const maxPage = Math.floor(total / newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(Math.min(page, maxPage));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleFocus = (field) => {
    setFocusState((prevState) => ({
      ...prevState,
      [field]: true,
    }));
  };

  const handleBlur = (field) => {
    setFocusState((prevState) => ({
      ...prevState,
      [field]: false,
    }));
  };

  const handleClosePopup = () => {
    setIsPopupOpenUpdate(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditNomination((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrorsUpdate((prev) => ({ ...prev, [name]: "" }));
  };

  const handleToggle = (e) => {
    const isChecked = e.target.checked;
    const newStatus = isChecked ? 2 : 1;

    setEditNomination({
      ...editNomination,
      status: newStatus,
    });
  };

  const handleEditClick = (nomination) => {
    
    const updatedNomination = {
      ...nomination,
      status: nomination.therapist_amount ? 2 : 1,
    };
    setEditNomination(updatedNomination);
    setIsPopupOpenUpdate(true);
  };

  const handleDeleteClick = (nomination) => {
    setNominationToDelete(nomination);
    setIsPopupOpenDelete(true);
  };

  return {
    arrNominations,
    activeSortColumn,
    sortColumnDirection,
    sortedData,
    newNomination,
    toggle,
    rowsPerPage,
    page,
    total,
    editNomination,
    isPopupOpenDelete,
    isPopupOpenUpdate,
    nominationToDelete,
    focusState,
    nameInputRef,
    errorsUpdate,
    errors,
    handleRowsPerPageChange,
    handlePageChange,
    handleSort,
    handleInputChange,
    handleAddNomination,
    handleUpdateNomination,
    handleDeleteNomination,
    handleToggleChange,
    handleToggle,
    handleFocus,
    handleBlur,
    handleClosePopup,
    handleChange,
    handleEditClick,
    handleDeleteClick,
    setIsPopupOpenDelete,
  };
};

export default useNominationMaster;
