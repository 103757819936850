import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Container,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import usePaginate from "../../hooks/usePaginate";
import { handleSetErrorToField } from "../../utils";
import {
  createGenres,
  deleteGenres,
  getListGenres,
  updateGenres,
} from "../../api/genres";
import { STALE_TIME_DEFAULT } from "../../constants";
import { useQuery } from "react-query";
import TablePaginationLayout from "../../layouts/TablePagination";
import styleCustom from "./style.module.css";

const StyledTextArea = styled("textarea")(({ theme }) => ({
  backgroundColor: "transparent",
  width: "100%",
  outline: "none",
  marginTop: "30px",
  borderRadius: "5px",
  fontSize: "18px",
  height: "60px",
}));

const columns = [
  { value: "id", label: "id" },
  { value: "name", label: "入力者名" },
  { value: "remarks", label: "メモ" },
];

const defaultValue = {
  id: "",
  name: "",
  remarks: "",
  color: "#000",
};

function PopupGenreMaster({ onClose, reftchList }) {
  const { page, perPage, total, changePage, setTotal, changePerPage } =
    usePaginate(0, 10);

  const [genresList, setGenresList] = useState([]);
  const [textInput, setTextInput] = useState("");
  const [keySearch, setKeySearch] = useState("");
  const [clickCount, setClickCount] = useState(0);

  const {
    control,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
  });

  const id = useWatch({ control, name: "id", defaultValue: "" });
  const name = useWatch({ control, name: "name", defaultValue: "" });
  const remarks = useWatch({ control, name: "remarks", defaultValue: "" });
  const color = useWatch({ control, name: "color", defaultValue: "#000" });

  const { data: dataGenres, refetch } = useQuery(
    ["list_genres", keySearch, page, perPage],
    () => {
      return getListGenres({
        name: keySearch,
        per_page: perPage,
        page: page + 1,
      });
    },
    {
      keepPreviousData: true,
      staleTime: STALE_TIME_DEFAULT,
    }
  );

  useEffect(() => {
    try {
      if (dataGenres) {
        setGenresList(dataGenres?.data?.data);
        setTotal(dataGenres?.data?.total);
      }
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGenres]);

  const handleDoubleClickRecord = (item) => {
    setClickCount((prev) => prev + 1);
    if (clickCount + 1 >= 2) {
      setValue("id", item.id || "");
      setValue("name", item.name || "");
      setValue("remarks", item.remarks || "");
      setValue("color", item.color || "#000");

      setClickCount(0);
    }

    setTimeout(() => {
      setClickCount(0);
    }, 300);
  };

  const handleCreateInputter = async () => {
    try {
      if (id) {
        alert("同じ名前が存在します。");
        return;
      }
      if (name.trim() === "") {
        handleSetErrorToField(
          {
            name: "Name field is required.",
          },
          setError
        );
        return;
      }

      // eslint-disable-next-line no-restricted-globals
      if (confirm("入力者を登録しますか？")) {
        const res = await createGenres({ name, remarks, color });

        alert(res?.message || "Create success");
        reset(defaultValue);
        refetch();
        reftchList();
      }
    } catch (error) {
      alert("Create error");
    }
  };

  const handleUpdateRecord = async () => {
    try {
      if (!id) {
        alert("変更する入力者が選択されていません。");
        return;
      }

      if (name.trim() === "") {
        handleSetErrorToField(
          {
            name: "Name field is required.",
          },
          setError
        );
        return;
      }

      // eslint-disable-next-line no-restricted-globals
      if (confirm("入力者情報を変更しますか？")) {
        const res = await updateGenres(id, { name, remarks, color });
        alert(res?.message || "Update success");
        reset(defaultValue);
        refetch();
        reftchList();
      }
    } catch (error) {
      alert("Update error");
    }
  };

  const handleDeleteRecord = async () => {
    try {
      if (!id) {
        alert("削除する入力者を選択する必要があります。");
        return;
      }

      // eslint-disable-next-line no-restricted-globals
      if (confirm("入力者を削除しますか？")) {
        const res = await deleteGenres(id);
        alert(res?.message || "Delete success");
        reset(defaultValue);
        refetch();
        reftchList();
      }
    } catch (error) {
      alert("Delete error");
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        border: "4px solid #d7d0c5",
        borderRadius: "8px",
        boxShadow: "24",
        height: "620px",
        overflow: "auto",
        paddingBottom: "6",
        width: "70%",
        top: "100px",
        left: "50%",
        transform: "translateX(-50%)",
        position: "absolute",
      }}
    >
      <AppBar
        position="static"
        elevation={4}
        sx={{
          backgroundColor: "rgb(136, 160, 185)",
        }}
      >
        <Toolbar>
          <Typography variant="h6">ジャンルマスター</Typography>
          <Button
            color="primary"
            style={{ marginLeft: "auto" }}
            onClick={onClose}
          >
            <SvgIcon
              component={CancelPresentationIcon}
              style={{ color: "white", fontSize: 48 }}
            />
          </Button>
        </Toolbar>
      </AppBar>

      <Container>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { mt: 2, width: "100%" },
            marginTop: " 64px",
          }}
        >
          <form>
            <Box sx={{ display: "flex", alignItems: "flex-end", gap: "10px" }}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{ required: "入力者名は必須です" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="入力者名"
                    required
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.name}
                    helperText={errors.name ? errors.name.message : null}
                  />
                )}
              />
              <Box sx={{ width: "100px" }}>
                <Controller
                  name="color"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="color"
                      {...field}
                      className={styleCustom.inputColor}
                    />
                  )}
                />
                <span>{color}</span>
              </Box>
            </Box>

            <Controller
              name="remarks"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <StyledTextArea
                  {...field}
                  rows="3"
                  aria-label="minimum height"
                  placeholder="メモ"
                />
              )}
            />

            <ButtonGroup
              variant="outlined"
              fullWidth
              aria-label="outlined button group"
              sx={{ mt: 3 }}
            >
              <Button
                type="button"
                color="success"
                size="large"
                onClick={handleCreateInputter}
              >
                登録
              </Button>
              <Button
                type="button"
                color="secondary"
                size="large"
                onClick={handleUpdateRecord}
              >
                編集
              </Button>
              <Button
                type="button"
                color="error"
                size="large"
                onClick={handleDeleteRecord}
              >
                削除
              </Button>
            </ButtonGroup>
          </form>
        </Box>
        <Box
          sx={{
            marginTop: " 80px",
            marginBottom: " 80px",
          }}
        >
          <Paper elevation={1}>
            <Typography
              variant="h6"
              sx={{
                minHeight: "56px",
                display: "flex",
                alignItems: "center",
                paddingLeft: "16px",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              入力者テーブル
            </Typography>
            <div className="search-wrapper">
              <input
                id="search"
                type="text"
                placeholder="検索"
                className="input-main"
                value={textInput}
                onChange={(e) => setTextInput(e.target.value)}
              />
              <button
                className="button-cancel"
                onClick={() => setKeySearch(textInput)}
              >
                X
              </button>
            </div>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id}>{column.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {genresList.map((item) => (
                    <TableRow
                      key={item.id}
                      onClick={() => handleDoubleClickRecord(item)}
                    >
                      {columns.map((column) => (
                        <TableCell key={column.value}>
                          {item[column.value]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationLayout
              rowsPerPage={perPage}
              total={total}
              page={page}
              rowsPerPageOptions={[10, 15, 20, 25, 30]}
              onPageChange={changePage}
              handleChangeRowsPerPage={changePerPage}
            />
          </Paper>
        </Box>
      </Container>
    </Box>
  );
}

export default PopupGenreMaster;
