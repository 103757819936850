import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/ja";
import "moment-timezone";
import "./index.css";
import React, { useCallback, useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Box, Dialog, DialogTitle, Modal, Typography } from "@mui/material";
import PopupEventComponent from "../PopupEvent";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { getListStaffSchedule } from "../../api/staffSchedule";
import { STALE_TIME_DEFAULT } from "../../constants";

moment.locale("ja");

const localizer = momentLocalizer(moment);

const eventPropGetter = (event, start, end, isSelected) => {
  var backgroundColor = event.bgColor;
  var textColor = event.color;
  var style = {
    backgroundColor: backgroundColor,
    borderRadius: "3px",
    opacity: 1,
    border: "none",
    color: textColor,
  };
  return {
    style: style,
  };
};

const calendarStyle = (date) => {
  const currentDate = `${new Date().getDate()} ${
    new Date().getMonth() + 1
  } ${new Date().getFullYear()}`;
  const allDate = `${date.getDate()} ${
    date.getMonth() + 1
  } ${date.getFullYear()}`;

  if (allDate === currentDate) {
    return {
      style: {
        backgroundColor: "#d6fad5",
      },
    };
  }

  if (date.getDay() === 0) {
    return {
      style: {
        backgroundColor: "#f8e0e0",
      },
    };
  }

  if (date.getDay() === 6) {
    return {
      style: {
        backgroundColor: "#ceecf5",
      },
    };
  }

  return {};
};

const formats = {
  monthHeaderFormat: "YYYY年 MM月",
  weekdayFormat: (date, culture, localizer) =>
    localizer.format(date, "dddd", culture),
  timeGutterFormat: (date, culture, localizer) =>
    localizer.format(date, "HH:mm", culture),
  agendaTimeFormat: (date, culture, localizer) =>
    localizer.format(date, "HH:mm", culture),
  eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
    localizer.format(start, "HH:mm", culture) +
    " - " +
    localizer.format(end, "HH:mm", culture),
  agendaHeaderFormat: ({ start, end }, culture, localizer) =>
    localizer.format(start, "YYYY/MM/DD", culture) +
    " - " +
    localizer.format(end, "YYYY/MM/DD", culture),
  agendaDateFormat: (date, culture, localizer) =>
    localizer.format(date, "MM月DD日", culture),
  agendaTimeRangeFormat: ({ start, end }, culture, localizer) =>
    localizer.format(start, "HH:mm", culture) +
    " - " +
    localizer.format(end, "HH:mm", culture),
  dayHeaderFormat: (date, culture, localizer) =>
    localizer.format(date, "MM月 DD日(ddd)", culture),
  dayFormat: (date, culture, localizer) =>
    localizer.format(date, "D日(ddd)", culture),
  dateFormat: (date, culture, localizer) =>
    localizer.format(date, "DD日", culture),
};

const CustomEvent = ({ event }) => <span>{event.title}</span>;

const CustomEventWrapper = ({ children }) => <div>{children}</div>;

function CalendarComponent() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [popups, setPopups] = useState([]);
  const [detailModalIsOpen, setDetailModalIsOpen] = useState(false);
  const [currentView, setCurrentView] = useState("month");
  const [selectableMonth] = useState(true);

  const [scheduleList, setScheduleList] = useState([]);
  const [groupSchedule, setGroupSchedule] = useState([]);
  const [scheduleSelected, setScheduleSelected] = useState();
  const [dateSelected, setDateSelected] = useState(null);

  const handleDataSchedule = (data) => {
    const result = Object.values(data)
      .flat()
      .map((item) => {
        return {
          id: item.id,
          title: item.title,
          start: dayjs(item.start_time).toDate(),
          end: dayjs(item.end_time).toDate(),
          bgColor: item.genre?.color,
          color: "#fff",
          detail: item,
        };
      });

    setScheduleList(result);
  };

  const { data: dataStaffSchedule, refetch: refetchStaffSchedule } = useQuery(
    ["list_all_staff_schedule"],
    () => {
      return getListStaffSchedule({
        start_date: "2024-09-01",
        end_date: "2024-09-31",
      });
    },
    {
      keepPreviousData: true,
      staleTime: STALE_TIME_DEFAULT,
    }
  );

  useEffect(() => {
    try {
      if (dataStaffSchedule) {
        handleDataSchedule(dataStaffSchedule.data);
      }
    } catch (error) {
      alert("Fetch data error!");
    }
  }, [dataStaffSchedule]);

  const handleSelectEvent = useCallback(
    (event) => {
      if (currentView === "day" || currentView === "week") {
        setScheduleSelected(event);
        setDetailModalIsOpen(true);
      } else {
        const group = scheduleList.filter(
          (e) =>
            e.start.toDateString() === event.start.toDateString() ||
            e.end.toDateString() === event.end.toDateString()
        );
        setGroupSchedule(group);
        setDateSelected(event.start);
        setModalIsOpen(true);
      }
    },
    [currentView, scheduleList]
  );

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSelectSlot = useCallback(
    ({ start, end, slotInfo }) => {
      if (selectableMonth) {
        setDateSelected(start);
        setScheduleSelected(undefined);
        setDetailModalIsOpen(true);
      } else {
        const newPopups = [];
        for (let i = 0; i < 10; i++) {
          newPopups.push({ id: i, message: `Popup ${i + 1}` });
        }
        setPopups(newPopups);
      }
    },
    [selectableMonth]
  );

  const handleEventClick = (event) => {
    setScheduleSelected(event);
    setDetailModalIsOpen(true);
    setModalIsOpen(false);
  };

  const closeDetailModal = () => {
    setDetailModalIsOpen(false);
    setScheduleSelected(undefined);
  };

  const handleViewChange = (view) => {
    setCurrentView(view);
  };

  return (
    <>
      <Calendar
        localizer={localizer}
        events={scheduleList}
        startAccessor="start"
        endAccessor="end"
        defaultView="month"
        messages={{
          today: "今日",
          previous: "前",
          next: "次",
          month: "月別",
          week: "週別",
          day: "日別",
          agenda: "リスト",
          showMore: (total) => `+${total}`,
        }}
        onView={handleViewChange}
        components={{
          event: CustomEvent,
          eventWrapper: CustomEventWrapper,
        }}
        formats={formats}
        dayPropGetter={calendarStyle}
        eventPropGetter={eventPropGetter}
        popup
        selectable={selectableMonth}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
      />
      {popups.map((popup) => (
        <div
          key={popup.id}
          style={{
            position: "absolute",
            top: 20 + popup.id * 30,
            left: 20,
            padding: 10,
            background: "white",
            border: "1px solid black",
          }}
        >
          {popup.message}
        </div>
      ))}
      <Dialog
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="event-details-title"
      >
        <DialogTitle id="event-details-title">
          <Typography align="center">
            {dateSelected ? dayjs(dateSelected).format("YYYY年MM月DD日") : ""}
          </Typography>
        </DialogTitle>

        <Box sx={{ padding: "16px" }}>
          {groupSchedule.map((event) => (
            <Typography
              key={event.id}
              style={{
                backgroundColor: event.bgColor || "#3174ad",
                color: "white",
                border: event.bgColor ? "none" : `1px solid #000`,
                borderRadius: "3px",
                opacity: 1,
                margin: "0 0 2px",
                padding: "0 4px",
                cursor: "pointer",
              }}
              onClick={() => handleEventClick(event)}
            >
              {event.title}
            </Typography>
          ))}
        </Box>
      </Dialog>
      <Modal
        open={detailModalIsOpen}
        onClose={closeDetailModal}
        aria-labelledby="transition-modal-title"
      >
        <PopupEventComponent
          handleClose={closeDetailModal}
          item={scheduleSelected}
          dateSelected={dateSelected}
          refetchStaffSchedule={refetchStaffSchedule}
        />
      </Modal>
    </>
  );
}

export default CalendarComponent;
