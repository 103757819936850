import { Box, Button, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CustomTopButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.text.primary,
  marginRight: theme.spacing(2),
  '&:hover': {
    backgroundColor: theme.palette.grey[400],
  },
}));

const buttons = [
  { text: "コース登録", route:"/accountmaster_course" },
  { text: "指名", route:"/accountmaster_assign" },
  { text: "オプション", route:"/accountmaster_option" },
  { text: "支払い方法", route:"/accountmaster_payment" },
  { text: "延長", route:"/accountmaster_extend" },
];

function NavButton() {
  const navigate = useNavigate();

  const handleMenuItemClick = (route) => {
    navigate(route);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "20px",
        }}
      >
        {buttons.map((button, index) => (
        <CustomTopButton key={index} variant="contained" onClick={()=>handleMenuItemClick(button.route)}>
          {button.text}
        </CustomTopButton>
      ))}
      </Box>
    </>
  );
}

export default NavButton;
