import { useEffect, useState } from "react";
import { getListRevenue } from "../../api/revenue";
import { useQuery } from "react-query";

const useRevenueByMonth = () => {
  const [arrRevenues, setArrRevenues] = useState([]);
  const [arrChartRevenues, setArrChartRevenues] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [currentYear, setCurrentYear] = useState("");
  const typeSearch = "by-month";

  const fetchRevenueData = async (year) => {
    const start_month = `${year}-01`;
    const end_month = `${year}-12`;
    const params = {
      start_month,
      end_month,
    };
    const data = await getListRevenue(typeSearch, params);
    return data;
  };

  const { data, refetch } = useQuery(
    ["revenue", currentYear],
    () => fetchRevenueData(currentYear),
    {
      keepPreviousData: true,
      enabled: Boolean(currentYear),
    }
  );

  useEffect(() => {
    if (data) {
      const transformedData = transformRevenueData(data.data);
      setArrRevenues(transformedData);

      setArrChartRevenues(transformChartRevenueData(transformedData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const date = new Date();
    const month = date.getFullYear();
    setCurrentYear(month);
  }, []);

  const transformRevenueData = (data) => {
    return data.map((revenue) => {
      const total_price = parseInt(revenue.total_price, 10);
      const total_customer = parseInt(revenue.total_customer, 10) || 1;
      const avg_price_per_customer = total_price / total_customer;

      return {
        month: formatJapaneseDate(revenue.month),
        total_price: formatCurrency(total_price),
        total_customer: formatNumberWithUnit(total_customer, "人"),
        avg_price_per_customer: formatCurrency(avg_price_per_customer),
        total_nomination: formatNumberWithUnit(revenue.total_nomination, "件"),
        nomination_fee: formatCurrency(revenue.nomination_fee),
      };
    });
  };

  const transformChartRevenueData = (data) => {
    return data.map((revenue) => {
      const total_price = parseInt(revenue.total_price.replace(/[^0-9]/g, ""), 10);
      const total_customer = parseInt(revenue.total_customer.replace(/[^0-9]/g, ""), 10) || 1;
      const avg_price_per_customer = total_price / total_customer;
      return {
        month: extractMonth(revenue.month),
        total_price,
        avg_price_per_customer,
        nomination_fee: parseInt(revenue.nomination_fee.replace(/[^0-9]/g, ""), 10),
      };
    });
  };

  const extractMonth = (formattedMonth) => {
    return formattedMonth.split("年")[1];
  };

  const formatJapaneseDate = (date) => {
    const d = new Date(date);
    return `${d.getFullYear()}年${d.getMonth() + 1}月`;
  };

  const formatCurrency = (value) => {
    return `${parseInt(value).toLocaleString()}円`;
  };

  const formatNumberWithUnit = (value, unit) => {
    return `${parseInt(value)}${unit}`;
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChangeYear = (e) => {
    const value = e.target.value;
    if (value === "") {
      setCurrentYear("");
      return;
    }
    const newYear = parseInt(value, 10);
    if (!isNaN(newYear)) {
      setCurrentYear(newYear);
      refetch()
    }
  };

  return {
    arrRevenues,
    arrChartRevenues,
    isFocused,
    currentYear,
    handleFocus,
    handleBlur,
    handleChangeYear,
  };
};

export default useRevenueByMonth;
