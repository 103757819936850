import {
  Box,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import HeaderReservationManagement from "../../layouts/HeaderReservationManagement";
import Navbar from "../../layouts/Navbar";
import TitleComponent from "../../components/Title";
import ChartComponent from "../../components/Chart";
import NavigationSaleMGR from "../../components/NavSaleMGR";
import useRevenueByMonth from "../../features/revenue/byMonth";

const headers = ["", "総売上", "総客数", "客単価", "指名売上", "指名数"];

function SalesManagementByMonth() {
  const {
    arrRevenues,
    arrChartRevenues,
    isFocused,
    currentYear,
    handleFocus,
    handleBlur,
    handleChangeYear
  } = useRevenueByMonth();

  return (
    <>
      <HeaderReservationManagement />
      <Navbar />
      <NavigationSaleMGR/>
      <Container maxWidth="md" sx={{ marginTop: "45px" }}>
        <TitleComponent valueText={"月別【売上集計】"} />
      </Container>
      <Container maxWidth="lg">
        <Grid container sx={{ justifyContent: "flex-end" }}>
          <Grid item>
            <FormControl sx={{ margin: "16px 0 0" }}>
              <InputBase
                className="MuiInput-root MuiInput-underline MuiInputBase-adornedEnd"
                id="input_month"
                name="input_month"
                type="number"
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={currentYear}
                onChange={handleChangeYear}
                sx={{
                  position: "relative",
                  "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before":
                    {
                      borderBottom: "1px solid rgb(0, 0, 0,0.42)",
                      left: 0,
                      bottom: 0,
                      content: '""',
                      position: "absolute",
                      right: 0,
                      transition:
                        "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                      pointerEvents: "none",
                    },
                  "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover::before":
                    {
                      borderBottom: "2px solid rgb(0, 0, 0)",
                    },
                  "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::after":
                    {
                      borderBottom: "2px solid rgb(25, 118, 210)",
                      left: 0,
                      bottom: 0,
                      content: '""',
                      position: "absolute",
                      right: 0,
                      transform: isFocused
                        ? "translateX(0px) scaleX(1)"
                        : "scale(0)",
                      transition:
                        "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                      pointerEvents: "none",
                    },
                }}
                endAdornment={
                  <InputAdornment position="end" variant="standard">
                    <Typography>月</Typography>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ padding: "40px", backgroundColor: "rgb(255, 255, 255)" }}>
          <ChartComponent dataset={arrChartRevenues}/>
        </Box>
        <Box sx={{ padding: "40px 0" }}>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map((header, index) => (
                    <TableCell
                      key={index}
                      scope="col"
                      sx={{
                        "&.MuiTableCell-head": {
                          backgroundColor: "rgb(0, 0, 0)",
                          color: "rgb(255, 255, 255)",
                        },
                      }}
                      align="center"
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {arrRevenues.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:nth-of-type(2n+1)": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                      },
                    }}
                  >
                    <TableCell
                      sx={{ "&.MuiTableCell-body": { fontSize: "14px" } }}
                      align="center"
                    >
                      <Typography sx={{ fontSize: "20px" }}>
                        {row.month}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography sx={{ fontSize: "20px" }}>
                        {row.total_price}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography sx={{ fontSize: "20px" }}>
                        {row.total_customer}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography sx={{ fontSize: "20px" }}>
                        {row.avg_price_per_customer}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography sx={{ fontSize: "20px" }}>
                        {row.nomination_fee}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography sx={{ fontSize: "20px" }}>
                        {row.total_nomination}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Box>
      </Container>
    </>
  );
}

export default SalesManagementByMonth;
