export const getCost = (list = [], id) => {
  try {
    const obj = list.find((item) => item.id === id);
    if (!obj) return 0;
    return Number(obj?.cost) || 0;
  } catch (error) {
    return 0;
  }
};

export const getDuration = (list = [], id, key) => {
  try {
    const obj = list.find((item) => item.id === id);
    if (!obj) return 0;
    return obj?.[key] || 0;
  } catch (error) {}
};

export const handleSetErrorToField = (objError, setError) => {
  for (let key in objError) {
    setError(key, {
      type: "manual",
      message: objError[key],
    });
  }
};

export const getCountOrderNominatation = (list, id) => {
  const nomination = list.find((item) => item.id === id);
  return nomination?.count_order || 0;
};

export const getValueBoolean = (value) => {
  return value ? "〇" : "×";
};
